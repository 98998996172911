<template>
  <v-form
    v-model="resetPasswordFormIsValid"
    ref="resetPasswordForm"
    class="form padding-x-0"
    @submit.prevent="resetPassword"
  >
    <div class="form__logo">
      <LogoBase />
    </div>
    <div class="form__title">
      {{ $t("authPages.loginPage.formTitle") }}
    </div>
    <div class="form__body">
      <v-text-field
        v-model="formData.newPassword"
        outlined
        :label="$t('labels.newPassword')"
        persistent-placeholder
        dense
        :type="newPasswordIconActive ? 'text' : 'password'"
        placeholder="**************************"
        :rules="[validations.required, validations.password]"
      >
        <template v-slot:append>
          <v-icon
            :color="newPasswordIconActive ? 'primary' : 'gray'"
            @click="newPasswordIconActive = !newPasswordIconActive"
          >
            {{ newPasswordIconActive ? "mdi-eye-off" : "mdi-eye" }}
          </v-icon>
        </template>
      </v-text-field>

      <v-text-field
        v-model="formData.repeatPassword"
        outlined
        :label="$t('labels.repeatPassword')"
        persistent-placeholder
        dense
        :type="repeatPasswordIconActive ? 'text' : 'password'"
        placeholder="**************************"
        :rules="[validations.required, repeatPasswordDoesMatch]"
      >
        <template v-slot:append>
          <v-icon
            :color="repeatPasswordIconActive ? 'primary' : 'gray'"
            @click="repeatPasswordIconActive = !repeatPasswordIconActive"
          >
            {{ repeatPasswordIconActive ? "mdi-eye-off" : "mdi-eye" }}
          </v-icon>
        </template>
      </v-text-field>

      <v-btn
        color="primary"
        depressed
        type="submit"
        class="form__submit"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t("userActions.confirm") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { UserService } from "@/services/user.service.js";
import validations from "@/validations";

export default {
  name: "ResetPasswordForm",
  components: {
    LogoBase: () => import("~cp/Logo/LogoBase"),
  },
  data() {
    return {
      loading: false,
      resetPasswordFormIsValid: false,
      formData: {
        newPassword: "",
        repeatPassword: "",
      },
      newPasswordIconActive: false,
      repeatPasswordIconActive: false,
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  methods: {
    repeatPasswordDoesMatch(val) {
      return val === this.formData.newPassword
        ? true
        : this.$t("validations.password.passwordDontMatch");
    },
    resetPassword() {
      this.$refs.resetPasswordForm.validate();
      if (!this.resetPasswordFormIsValid) return;
      this.loading = true;
      UserService.resetPassword({
        password: this.formData.newPassword,
        token: this.$route.query.token,
      })
        .then(() => {
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.passwordReset")
          );
          this.$router.push({ name: "LoginPage" });
        })
        .catch(({response}) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style src="../Form.scss" lang="scss" scoped></style>
